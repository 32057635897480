define("discourse/plugins/discourse-pkv/discourse/initializers/home-edits", ["exports", "discourse/lib/utilities", "discourse/lib/plugin-api", "discourse/lib/decorators"], function (_exports, _utilities, _pluginApi, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'home-edits',
    initialize(container) {
      const currentUser = container.lookup('current-user:main');
      const siteSettings = container.lookup('site-settings:main');
      if (!currentUser || !currentUser.homepage_id) (0, _utilities.setDefaultHomepage)('home');
      (0, _pluginApi.withPluginApi)('0.8.23', api => {
        api.modifyClass('controller:preferences/interface', Superclass => class extends Superclass {
          userSelectableHome() {
            let core = super.userSelectableHome;
            core.push(...[{
              name: "Home",
              value: 101
            }]);
            return core;
          }
          static #_ = (() => dt7948.n(this.prototype, "userSelectableHome", [(0, _decorators.default)()]))();
          homeChanged() {
            const homepageId = this.get("model.user_option.homepage_id");
            if (homepageId === 101) {
              (0, _utilities.setDefaultHomepage)("home");
            } else if (homepageId === 102) {
              (0, _utilities.setDefaultHomepage)("assigned");
            } else {
              super.homeChanged();
            }
          }
        });
      });
    }
  };
});