define("discourse/plugins/discourse-pkv/discourse/initializers/clickable-banner", ["exports", "discourse/lib/plugin-api", "@ember/template"], function (_exports, _pluginApi, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "clickable-banner",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.1", api => {
        console.log('modifying banner to be clickable');
        api.modifyClass("component:discourse-banner", DiscourseBanner => class extends DiscourseBanner {
          get content() {
            const content = super.content;
            return (0, _template.htmlSafe)(`<a style="color: inherit" href="/t/${super.banner.key}">${content}</a>`);
          }
        });
      });
    }
  };
});