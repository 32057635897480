define("discourse/plugins/discourse-pkv/discourse/components/news-topic", ["exports", "@glimmer/component", "@ember/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class NewsTopic extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="news-topic">
          <div class="image-div">
            <img src={{@topic.image_url}} class="banner-image" />
          </div>
          <div class="overlay"></div>
          <a class="content" href={{@topic.url}}>
            <div class="title-container">
              <h4>
                {{#if @topic.unicode_title }}
                  {{ @topic.unicode_title }}
                {{ else}}
                  {{ @topic.title }}
                {{/if}}
              </h4>
            </div>
          </a>
        </div>
      
    */
    {
      "id": "4H0NGOMv",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"news-topic\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"image-div\"],[12],[1,\"\\n        \"],[10,\"img\"],[15,\"src\",[30,1,[\"image_url\"]]],[14,0,\"banner-image\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"overlay\"],[12],[13],[1,\"\\n      \"],[10,3],[14,0,\"content\"],[15,6,[30,1,[\"url\"]]],[12],[1,\"\\n        \"],[10,0],[14,0,\"title-container\"],[12],[1,\"\\n          \"],[10,\"h4\"],[12],[1,\"\\n\"],[41,[30,1,[\"unicode_title\"]],[[[1,\"              \"],[1,[30,1,[\"unicode_title\"]]],[1,\"\\n\"]],[]],[[[1,\"              \"],[1,[30,1,[\"title\"]]],[1,\"\\n\"]],[]]],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@topic\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-pkv/discourse/components/news-topic.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = NewsTopic;
});